import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./music.css"
import React from 'react';
import { useState,useEffect } from "react";
import {Link} from 'react-router-dom'
import { MdSkipNext,MdSkipPrevious}from "react-icons/md";

import { useNavigate ,useParams } from "react-router-dom";
import { Container,Row,Col } from 'react-bootstrap'
// import ReactAudioPlayer from 'react-audio-player';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
// import { Helmet } from 'react-helmet';


  




const Music = () => {
      const [currentSong, setCurrentSong] = useState(null);
  const [filteredsongs,setFilteredSongs ] = useState([]);

  const [popularSongs, setPopularSongs] = useState([]);
  const [selectedSongIndex, setSelectedSongIndex] = useState(0);
  const [popularMovies, setPopularMovies] = useState([]);
  const [popularMoviesT, setPopularMoviesT] = useState([]);
  const [popularMoviesP, setPopularMoviesP] = useState([]);
  const [popularMoviesM, setPopularMoviesM] = useState([]);
  const [popularMoviesH, setPopularMoviesH] = useState([]);
  const [popularMoviesK, setPopularMoviesK] = useState([]);
  const [popularMoviesO, setPopularMoviesO] = useState([]);
  const [popularMoviesR, setPopularMoviesR] = useState([]);
  const [popularMoviesTA, setPopularMoviesTA] = useState([]);
  const [popularMoviesB, setPopularMoviesB] = useState([]);
  // const [headert, setHeaderT] = useState(true);
  // const [headerh, setHeaderH] = useState(false);

  const [popularMoviesA, setPopularMoviesA] = useState([]);
  const [popularMoviesBE, setPopularMoviesBE] = useState([]);
  const [popularMoviesG, setPopularMoviesG] = useState([]);
  const [popularMoviesHI, setPopularMoviesHI] = useState([]);
  const [popularMoviesML, setPopularMoviesML] = useState([]);
  const [popularMoviesEN, setPopularMoviesEN] = useState([]);

//  const [headert,setHeaderT]=useState(true);
//  const [headerh,setHeaderH]=useState(false);
const [musicplayer,setMusicplayer]=useState(false);

// const [headere,setHeaderE]=useState(false);
	// var varialve=[];

// const headerT=()=>{

// setHeaderT(true);
// setHeaderH(false);
// setHeaderE(false);
// }
// const headerH=()=>{
//   setHeaderT(false);
//   setHeaderH(true);
// 	setHeaderE(false);
// }

// const headerE=()=>{
//   setHeaderT(false);
//   setHeaderH(false);
//   setHeaderE(true);
// }
 useEffect(() => {
  fetch("https://zingportal.beeinnovations.com:5002/zing-api/languageapi?language=hindi",{
   method:"POST",
   
 })
 .then(res => res.json())
 .then(json => 
  setPopularMovies(json.data))
console.log(popularMovies);
 
}, [])
const filtered = popularMovies.filter(employee => {

        return employee.name === 'Just In Hits';
  
      });
console.log(filtered) ;

 
useEffect(() => {
  fetch("https://zingportal.beeinnovations.com:5002/zing-api/languageapi?language=punjabi",{
   method:"POST",
   
 })
 .then(res => res.json())
 .then(json => setPopularMoviesP(json.data))
 
}, [])
const filteredP= popularMoviesP.filter(employee => {

        return employee.name === 'Punjabi Romance';
  
      });
console.log(filteredP) ;



useEffect(() => {
  fetch("https://zingportal.beeinnovations.com:5002/zing-api/languageapi?language=English",{
   method:"POST",

 })
 .then(res => res.json())
 .then(json => setPopularMoviesEN(json.data))

}, [])
const filteredEN= popularMoviesEN.filter(employee => {

        return employee.name === 'Movie Reviews';

      });
console.log(filteredEN) ;


  useEffect(() => {
    fetch("https://zingportal.beeinnovations.com:5002/zing-api/languageapi?language=telugu",{
     method:"POST",
     
   })
   .then(res => res.json())
   .then(json => setPopularMoviesT(json.data))
   
  }, [])
  const filteredT = popularMoviesT.filter(employee => {
  
          return employee.name === 'Retro Telugu Hits';
    
        });
  console.log(filteredT) ;
  
  

useEffect(() => {
  fetch("https://zingportal.beeinnovations.com:5002/zing-api/languageapi?language=marathi",{
   method:"POST",

 })
 .then(res => res.json())
 .then(json => setPopularMoviesM(json.data))

}, [])
const filteredM= popularMoviesM.filter(employee => {

        return employee.name === 'Marathi Happy Vibe ';

      });
console.log(filteredM) ;




useEffect(() => {
  fetch(
    "https://zingportal.beeinnovations.com:5002/zing-api/languageapi?language=bhojpuri",
    {
      method: "POST",
    }
  )
    .then((res) => res.json())
    .then((json) => setPopularMoviesB(json.data));
}, []);
const filteredB = popularMoviesB.filter((employee) => {
  return employee.name === "Maja Le Lo";
});
console.log(filteredB);


useEffect(() => {
  fetch(
    "https://zingportal.beeinnovations.com:5002/zing-api/languageapi?language=haryanvi",
    {
      method: "POST",
    }
  )
    .then((res) => res.json())
    .then((json) => setPopularMoviesH(json.data));
}, []);
const filteredH = popularMoviesH.filter((employee) => {
  return employee.name === "Unlimited Romance";
});
console.log(filteredH);

useEffect(() => {
  fetch(
    "https://zingportal.beeinnovations.com:5002/zing-api/languageapi?language=himachali",
    {
      method: "POST",
    }
  )
    .then((res) => res.json())
    .then((json) => setPopularMoviesHI(json.data));
}, []);
const filteredHI = popularMoviesHI.filter((employee) => {
  return employee.name === "Love In The Air";
});
console.log(filteredHI);
// console.log(popularMoviesHI);


useEffect(() => {
  fetch(
    "https://zingportal.beeinnovations.com:5002/zing-api/languageapi?language=kannada",
    {
      method: "POST",
   }
  )
    .then((res) => res.json())
    .then((json) => setPopularMoviesK(json.data));
}, []);
const filteredK = popularMoviesK.filter((employee) => {
  return employee.name === "Devigeete";
});
console.log(filteredK);



useEffect(() => {
  fetch(
    "https://zingportal.beeinnovations.com:5002/zing-api/languageapi?language=odia",
    {
      method: "POST",
    }
  )
    .then((res) => res.json())
    .then((json) => setPopularMoviesO(json.data));
}, []);
const filteredO = popularMoviesO.filter((employee) => {
  return employee.name === "Odissi Dance";
});
console.log(filteredO);

useEffect(() => {
  fetch(
    "https://zingportal.beeinnovations.com:5002/zing-api/languageapi?language=rajasthani",
    {
      method: "POST",
    }
  )
    .then((res) => res.json())
    .then((json) => setPopularMoviesR(json.data));
}, []);
const filteredR = popularMoviesR.filter((employee) => {
  return employee.name === "Mixed Music Box";
});
console.log(filteredR);

useEffect(() => {
  fetch(
    "https://zingportal.beeinnovations.com:5002/zing-api/languageapi?language=tamil",
    {
      method: "POST",
    }
  )
    .then((res) => res.json())
    .then((json) => setPopularMoviesTA(json.data));
}, []);
const filteredTA = popularMoviesTA.filter((employee) => {
  return employee.name === "Tamil Melody mashup";
});
console.log(filteredTA);



useEffect(() => {
  fetch(
    "https://zingportal.beeinnovations.com:5002/zing-api/languageapi?language=assamese",
    {
      method: "POST",
    }
  )
    .then((res) => res.json())
    .then((json) => setPopularMoviesA(json.data));
}, []);
const Assamese = popularMoviesA.filter((employee) => {
  return employee.name === "Assamese Romantic Hits";
});
console.log(Assamese);
// console.log(popularMoviesA)



useEffect(() => {
  fetch(
    "https://zingportal.beeinnovations.com:5002/zing-api/languageapi?language=malayalam",
    {
      method: "POST",
    }
  )
    .then((res) => res.json())
    .then((json) => setPopularMoviesML(json.data));
}, []);
const filteredML = popularMoviesML.filter((employee) => {
  return employee.name === "Shuffle All Day";
});
console.log(filteredML);
// console.log(popularMoviesML)


useEffect(() => {
  fetch(
    "https://zingportal.beeinnovations.com:5002/zing-api/languageapi?language=bengali",
    {
      method: "POST",
    }
  )
    .then((res) => res.json())
    .then((json) => setPopularMoviesBE(json.data));
}, []);
const filteredBE = popularMoviesBE.filter((employee) => {
  return employee.name === "Top Albums For You";
});
console.log(filteredBE);
// console.log(popularMoviesBE);


useEffect(() => {
  fetch(
    "https://zingportal.beeinnovations.com:5002/zing-api/languageapi?language=gujarati",
    {
      method: "POST",
    }
  )
    .then((res) => res.json())
    .then((json) => setPopularMoviesG(json.data));
}, []);
const filteredG = popularMoviesG.filter((employee) => {
  return employee.name === "All Time Maja Ma";
});
console.log(filteredG);


  

  const handleNextSong = () => {
    console.log(currentSong.id);

    if (currentSong.id) {
      const currentIndex = filteredsongs.findIndex(
        (song) => song.id === currentSong.id
      );
      console.log(currentIndex);
      if (currentIndex < filteredsongs.length - 1) {
        const nextSong = filteredsongs[currentIndex + 1];

        setCurrentSong(nextSong);
      } else {
        const index = 0;
        const nextSong = filteredsongs[index];
        setCurrentSong(nextSong);
      }
    }
    // console.log(popularSongs);
  };

  const handlePreviousSong = () => {
    console.log(currentSong.id);

    if (currentSong.id) {
      const currentIndex = filteredsongs.findIndex(
        (song) => song.id === currentSong.id
      );
      if (currentIndex < filteredsongs.length - 1 && currentIndex > 0) {
        const previousSong = filteredsongs[currentIndex - 1];
        setCurrentSong(previousSong);
        setSelectedSongIndex(currentIndex - 1);
      } else if (currentIndex <= 0) {
        const index = filteredsongs.length - 1;
        const previousSong = filteredsongs[index];
        setCurrentSong(previousSong);
        const currentIndex = index - 1;
        setSelectedSongIndex(currentIndex);
      } else if (currentIndex === filteredsongs.length - 1) {
        const previousSong = filteredsongs[currentIndex - 1];
        setCurrentSong(previousSong);
        setSelectedSongIndex(currentIndex - 1);
      }
    }
  };  


    let settings = {
      dots: false,
      // autoplay: false,
      autoplaySpeed: 5000,
      slidesToShow: 4,
      arrows: true,
      slidesToScroll: 1,
      // autoplay: true,

      responsive: [
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            arrows: true,
            // autoplay: true,
            slidesToScroll: 2,
            // initialSlide: 2
          },
        },
      ],
    };
 

{/* const nextpage1 = (id1, cp_url,description,singer,language) => {
      setPopularSongs([{ id: id1, cp_url , description: description,
        singer: singer,language:language}]);
      setMusicplayer(true);
    };  */}

 const nextpage1 = (id1, cp_url, description, singer,language,id2) => {
   const newSong = {
      id: id1,
    cp_url,
      description: description,
      singer:singer,
	   language: language,
      
      id2:id2
    };

    var encodedid1 = encodeURIComponent(id2);
console.log(encodedid1);
    fetch(
      `https://zingportal.beeinnovations.com:5002/zing-api/languageapibyid?id=${encodedid1}`,
      {
        method: "POST",
      }
    )
      .then((res) => res.json())
      .then((json) => setFilteredSongs(json.data));

   

    setPopularSongs((prevPopularSongs) => [...prevPopularSongs, newSong]);
    setSelectedSongIndex(0);
    setCurrentSong(newSong);
    setMusicplayer(true);
      
  };
   //onst handlePlay = () => {
     //onst song = popularSongs[selectedSongIndex];
     //etCurrentSong(song);
   //;
    // const handlePlay = () => {
    //   const song = popularSongs[0];
    //   setCurrentSong(song);
    // };


 



  return (
  
      <div className="music-player">

      <div className="header">
      <img src='./Zing_Logo-removebg-preview.png' alt='feg' className="imglogo"/>
      </div>
      {/* <Row>
        <Col >
        <div className='nava' >
           <div onClick={headerT} >
             <Link to="/">Telugu</Link>
           </div>
           <div onClick={headerH}>
             <Link to="/">Hindi</Link>
           </div>
           <div onClick={headerE}>
             <Link to="/">English</Link> */}
           {/* </div> */}
         
           {/* <div onClick={headerH}>
             <Link to="/">Assame</Link>
           </div>
           <div onClick={headerT}>
             <Link to="/">Malayalam</Link>
           </div>
           <div onClick={headerH}>
             <Link to="/">Kannada</Link>
           </div>
           <div onClick={headerH}>
             <Link to="/">Tamil</Link>
           </div> */}
          
         {/* </div>
        </Col>
      </Row> */}
       
       <div className="album">
        <Row>
        <Col >
      
     
      
      
      
                {/* <h1>Hindi</h1> */}
                <h3>   {filtered.map((item) => <p>{item.name}</p>)}<span>Hindi</span></h3> 
         
         <div className="main-cont1" >
      
         {filtered.map((item) => {
                return (
                  <div className="imgcont">
                    <Slider {...settings} className="slide">
                      {item.videodatamodel.map((viwdeo) => (
                        <div key={item.id} onClick={(e) => nextpage1(viwdeo.id, viwdeo.cp_url, viwdeo.description,viwdeo.singer,viwdeo.language,item.id)}>
                          <img
                            src={`https://zingapi.beeinnovations.com/previewthumbnail.php?c_id=${viwdeo.id}&ctype=MUSICD`}
                            alt="aff"
                            style={{ height: "300px", width: "180px" }}
                          />
                          <p style={{ textAlign: "center" }}>
                            {viwdeo.description} <br/>
			      {/*<span style={{fontSize:'10px'}}>{viwdeo.singer}</span>*/}
                      
                          </p>
                          {/* <p style={{ textAlign: "center",fontSize:'10px' }}>
                            {viwdeo.singer}
                          </p> */}
                        </div>
                      ))}
                    </Slider>
                  </div>
                );
              })}
              </div>
      </Col>
          <Col >
  
  {/* <h1>Telugu</h1> */}
      
  <h3>   {filteredT.map((item) => <p>{item.name}</p>)}<span>Telugu</span></h3> 

      
      <div className="main-cont1" >
      
      
      {filteredT.map((item) => {
                return (
                  <div className="imgcont">
                    <Slider {...settings} className="slide">
                      {item.videodatamodel.map((viwdeo) => (
                        <div key={item.id} onClick={(e) => nextpage1(viwdeo.id, viwdeo.cp_url, viwdeo.description,viwdeo.singer,viwdeo.language,item.id)}>
                          <img
                            src={`https://zingapi.beeinnovations.com/previewthumbnail.php?c_id=${viwdeo.id}&ctype=MUSICD`}
                            alt="aff"
                            style={{ height: "300px", width: "180px" }}
                          />
                           <p style={{ textAlign: "center" }}>
                            {viwdeo.description} <br/>
                              {/*<span style={{fontSize:'10px'}}>{viwdeo.singer}</span>*/}

                      
                          </p>
                          
                        </div>
                      ))}
                    </Slider>
                  </div>
                );
              })}
      </div>
          </Col>
           <Col > 
                {/* <h1>Punjabi</h1> */}
                <h3>   {filteredP.map((item) => <p>{item.name}</p>)}<span>Punjabi</span></h3> 

          
         <div className="main-cont1 " >
      
          
         {filteredP.map((item) => {
                return (
                  <div className="imgcont">
                    <Slider {...settings} className="slide">
                      {item.videodatamodel.map((viwdeo) => (
                        <div key={item.id} onClick={(e) => nextpage1(viwdeo.id, viwdeo.cp_url, viwdeo.description,viwdeo.singer,viwdeo.language,item.id)}>
                          <img
                            src={`https://zingapi.beeinnovations.com/previewthumbnail.php?c_id=${viwdeo.id}&ctype=MUSICD`}
                            alt="aff"
                            style={{ height: "300px", width: "180px" }}
                          />
                         <p style={{ textAlign: "center" }}>
                            {viwdeo.description} <br/>
                            {/*<span style={{fontSize:'10px'}}>{viwdeo.singer}</span>*/}

                      
                          </p>
                          
                        </div>
                      ))}
                    </Slider>
                  </div>
                );
              })}
            </div>
      
            
      
      </Col>
	       <Col >
                {/* <h1>Kannada</h1> */}
                <h3>   {filteredK.map((item) => <p>{item.name}</p>)}<span>Kannada</span></h3>


         <div className="main-cont1 " >


         {filteredK.map((item) => {
                return (
                  <div className="imgcont">
                    <Slider {...settings} className="slide">
                      {item.videodatamodel.map((viwdeo) => (
                        <div key={item.id} onClick={(e) => nextpage1(viwdeo.id, viwdeo.cp_url, viwdeo.description,viwdeo.singer,viwdeo.language,item.id)}>
                          <img
                            src={`https://zingapi.beeinnovations.com/previewthumbnail.php?c_id=${viwdeo.id}&ctype=MUSICD`}
                            alt="aff"
                            style={{ height: "300px", width: "180px" }}
                          />
                         <p style={{ textAlign: "center" }}>
                            {viwdeo.description} <br/>
                            

                          </p>

                        </div>
                      ))}
                    </Slider>
                  </div>
                );
              })}
            </div>



      </Col>

	    <Col >
                {/* <h1>English</h1> */}
                <h3>   {filteredEN.map((item) => <p>{item.name}</p>)}<span>English</span></h3>


         <div className="main-cont1 " >


         {filteredEN.map((item) => {
                return (
                  <div className="imgcont">
                    <Slider {...settings} className="slide">
                      {item.videodatamodel.map((viwdeo) => (
                        <div key={item.id} onClick={(e) => nextpage1(viwdeo.id, viwdeo.cp_url, viwdeo.description,viwdeo.singer,viwdeo.language,item.id)}>
                          <img
                            src={`https://zingapi.beeinnovations.com/previewthumbnail.php?c_id=${viwdeo.id}&ctype=MUSICD`}
                            alt="aff"
                            style={{ height: "300px", width: "180px" }}
                          />
                         <p style={{ textAlign: "center" }}>
                            {viwdeo.description} <br/>
                            

                          </p>

                        </div>
                      ))}
                    </Slider>
                  </div>
                );
              })}
            </div>



      </Col>

      <Col>
                {/* <h1>Marathi</h1> */}
                <h3>   {filteredM.map((item) => <p>{item.name}</p>)}<span>Marathi</span></h3> 
    
         
         <div className="main-cont1 " >
      
          
         {filteredM.map((item) => {
                return (
                  <div className="imgcont">
                    <Slider {...settings} className="slide">
                      {item.videodatamodel.map((viwdeo) => (
                        <div key={item.id} onClick={(e) => nextpage1(viwdeo.id, viwdeo.cp_url, viwdeo.description,viwdeo.singer,viwdeo.language,item.id)}>
                          <img
                            src={`https://zingapi.beeinnovations.com/previewthumbnail.php?c_id=${viwdeo.id}&ctype=MUSICD`}
                            alt="aff"
                            style={{ height: "300px", width: "180px" }}
                          />
                          <p style={{ textAlign: "center" }}>
                            {viwdeo.description} <br/>
                           
                      
                          </p>
                        
                        </div>
                      ))}
                    </Slider>
                  </div>
                );
              })}
            </div>
      
      </Col>



      <Col>
            {/* <h3>Bhojpuri</h3> */}
            <h3>   {filteredB.map((item) => <p>{item.name}</p>)}<span>Bhojpuri</span></h3>

            {/* <p style={{ textAlign: "center" }}>{}</p> */}
            <div className="main-cont1">
              {filteredB.map((item) => {
                return (
                  <div className="imgcont">
                    <Slider {...settings} className="slide">
                      {item.videodatamodel.map((viwdeo) => (
                        <div key={item.id} onClick={(e) => nextpage1(viwdeo.id, viwdeo.cp_url, viwdeo.description,viwdeo.singer,viwdeo.language,item.id)}>
                          <img
                            src={`https://zingapi.beeinnovations.com/previewthumbnail.php?c_id=${viwdeo.id}&ctype=MUSICD`}
                            alt="aff"
                            style={{ height: "300px", width: "180px" }}
                          />
                           <p style={{ textAlign: "center" }}>
                            {viwdeo.description} <br/>
                           

                          </p>

                        </div>
                      ))}
                    </Slider>
                  </div>
                );
              })}
            </div>
          </Col>

       

          <Col>
            {/* <h3>Harayanvi</h3> */}
            <h3>   {filteredH.map((item) => <p>{item.name}</p>)}<span>Harayanvi</span></h3>

            {/* <p style={{ textAlign: "center" }}>{}</p> */}
            <div className="main-cont1">
              {filteredH.map((item) => {
                return (
                  <div className="imgcont">
                    <Slider {...settings} className="slide">
                      {item.videodatamodel.map((viwdeo) => (
                        <div key={item.id} onClick={(e) => nextpage1(viwdeo.id, viwdeo.cp_url, viwdeo.description,viwdeo.singer,viwdeo.language,item.id)}>
                          <img
                            src={`https://zingapi.beeinnovations.com/previewthumbnail.php?c_id=${viwdeo.id}&ctype=MUSICD`}
                            alt="aff"
                            style={{ height: "300px", width: "180px" }}
                          />
                           <p style={{ textAlign: "center" }}>
                            {viwdeo.description} <br/>
                         

                          </p>

                        </div>
                      ))}
                    </Slider>
                  </div>
                );
              })}
            </div>
          </Col>

	 

          <Col>
            {/* <h3>Odia</h3> */}
            <h3>   {filteredO.map((item) => <p>{item.name}</p>)}<span>Odia</span></h3>

            {/* <p style={{ textAlign: "center" }}>{}</p> */}
            <div className="main-cont1">
              {filteredO.map((item) => {
                return (
                  <div className="imgcont">
                    <Slider {...settings} className="slide">
                      {item.videodatamodel.map((viwdeo) => (
                        <div key={item.id} onClick={(e) => nextpage1(viwdeo.id, viwdeo.cp_url, viwdeo.description,viwdeo.singer,viwdeo.language,item.id)}>
                          <img
                            src={`https://zingapi.beeinnovations.com/previewthumbnail.php?c_id=${viwdeo.id}&ctype=MUSICD`}
                            alt="aff"
                            style={{ height: "300px", width: "180px" }}
                          />
                           <p style={{ textAlign: "center" }}>
                            {viwdeo.description} <br/>
                          

                          </p>

                        </div>
                      ))}
                    </Slider>
                  </div>
                );
              })}
            </div>
          </Col>

          <Col>
            {/* <h3>Rajasthani</h3> */}
            <h3>   {filteredR.map((item) => <p>{item.name}</p>)}<span>Rajasthani</span></h3>

            {/* <p style={{ textAlign: "center" }}>{}</p> */}
            <div className="main-cont1">
              {filteredR.map((item) => {
                return (
                  <div className="imgcont">
                    <Slider {...settings} className="slide">
                      {item.videodatamodel.map((viwdeo) => (
                        <div key={item.id} onClick={(e) => nextpage1(viwdeo.id, viwdeo.cp_url, viwdeo.description,viwdeo.singer,viwdeo.language,item.id)}>
                          <img
                            src={`https://zingapi.beeinnovations.com/previewthumbnail.php?c_id=${viwdeo.id}&ctype=MUSICD`}
                            alt="aff"
                            style={{ height: "300px", width: "180px" }}
                          />
                          <p style={{ textAlign: "center" }}>
                            {viwdeo.description} <br/>
                          

                          </p>

                        </div>
                      ))}
                    </Slider>
                  </div>
                );
              })}
            </div>
          </Col>

          <Col>
            {/* <h3>Tamil</h3> */}
            <h3>   {filteredTA.map((item) => <p>{item.name}</p>)}<span>Tamil</span></h3>

            {/* <p style={{ textAlign: "center" }}>{}</p> */}
            <div className="main-cont1">
              {filteredTA.map((item) => {
                return (
                  <div className="imgcont">
                    <Slider {...settings} className="slide">
                      {item.videodatamodel.map((viwdeo) => (
                        <div key={item.id} onClick={(e) => nextpage1(viwdeo.id, viwdeo.cp_url, viwdeo.description,viwdeo.singer,viwdeo.language,item.id)}>
                          <img
                            src={`https://zingapi.beeinnovations.com/previewthumbnail.php?c_id=${viwdeo.id}&ctype=MUSICD`}
                            alt="aff"
                            style={{ height: "300px", width: "180px" }}
                          />
                           <p style={{ textAlign: "center" }}>
                            {viwdeo.description} <br/>
                        

                          </p>

                        </div>
                      ))}
                    </Slider>
                  </div>
                );
              })}
            </div>
          </Col>



          <Col>
            {/* <h3>Assamese</h3> */}
            <h3>   {Assamese.map((item) => <p>{item.name}</p>)}<span>Assamese</span></h3>

            {/* <p style={{ textAlign: "center" }}>{}</p> */}
            <div className="main-cont1">
              {Assamese.map((item) => {
                return (
                  <div className="imgcont">
                    <Slider {...settings} className="slide">
                      {item.videodatamodel.map((viwdeo) => (
                        <div key={item.id} onClick={(e) => nextpage1(viwdeo.id, viwdeo.cp_url, viwdeo.description,viwdeo.singer,viwdeo.language,item.id)}>
                          <img
                            src={`https://zingapi.beeinnovations.com/previewthumbnail.php?c_id=${viwdeo.id}&ctype=MUSICD`}
                            alt="aff"
                            style={{ height: "300px", width: "180px" }}
                          />
                           <p style={{ textAlign: "center" }}>
                            {viwdeo.description} <br/>
                         

                          </p>

                        </div>
                      ))}
                    </Slider>
                  </div>
                );
              })}
            </div>
          </Col>


          <Col>
            {/* <h3>Bengali</h3> */}
            <h3>   {filteredBE.map((item) => <p>{item.name}</p>)}<span>Bengali</span></h3>

            {/* <p style={{ textAlign: "center" }}>{}</p> */}
            <div className="main-cont1">
              {filteredBE.map((item) => {
                return (
                  <div className="imgcont">
                    <Slider {...settings} className="slide">
                      {item.videodatamodel.map((viwdeo) => (
                        <div key={item.id} onClick={(e) => nextpage1(viwdeo.id, viwdeo.cp_url, viwdeo.description,viwdeo.singer,viwdeo.language,item.id)}>
                          <img
                            src={`https://zingapi.beeinnovations.com/previewthumbnail.php?c_id=${viwdeo.id}&ctype=MUSICD`}
                            alt="aff"
                            style={{ height: "300px", width: "180px" }}
                          />
                         
                           <p style={{ textAlign: "center" }}>
                            {viwdeo.description} <br/>
                            

                          </p>

                        </div>
                      ))}
                    </Slider>
                  </div>
                );
              })}
            </div>
          </Col>


          <Col>
            {/* <h3>Himachali</h3> */}
            <h3>   {filteredHI.map((item) => <p>{item.name}</p>)}<span>Himachali</span></h3>

            {/* <p style={{ textAlign: "center" }}>{}</p> */}
            <div className="main-cont1">
              {filteredHI.map((item) => {
                return (
                  <div className="imgcont">
                    <Slider {...settings} className="slide">
                      {item.videodatamodel.map((viwdeo) => (
                        <div key={item.id} onClick={(e) =>nextpage1(viwdeo.id, viwdeo.cp_url, viwdeo.description,viwdeo.singer,viwdeo.language,item.id)}>
                          <img
                            src={`https://zingapi.beeinnovations.com/previewthumbnail.php?c_id=${viwdeo.id}&ctype=MUSICD`}
                            alt="aff"
                            style={{ height: "300px", width: "180px" }}
                          />
                          <p style={{ textAlign: "center" }}>
                            {viwdeo.description} <br/>
                           

                          </p>

                        </div>
                      ))}
                    </Slider>
                  </div>
                );
              })}
            </div>
          </Col>
  
          <Col>
            {/* <h3>Gujarathi</h3> */}
            <h3>   {filteredG.map((item) => <p>{item.name}</p>)}<span>Gujarathi</span></h3>

            {/* <p style={{ textAlign: "center" }}>{}</p> */}
            <div className="main-cont1">
              {filteredG.map((item) => {
                return (
                  <div className="imgcont">
                    <Slider {...settings} className="slide">
                      {item.videodatamodel.map((viwdeo) => (
                        <div key={item.id} onClick={(e) => nextpage1(viwdeo.id, viwdeo.cp_url, viwdeo.description,viwdeo.singer,viwdeo.language,item.id)}>
                          <img
                            src={`https://zingapi.beeinnovations.com/previewthumbnail.php?c_id=${viwdeo.id}&ctype=MUSICD`}
                            alt="aff"
                            style={{ height: "300px", width: "180px" }}
                          />
                          <p style={{ textAlign: "center" }}>
                            {viwdeo.description} <br/>
                         

                          </p>

                        </div>
                      ))}
                    </Slider>
                  </div>
                );
              })}
            </div>
          </Col>


       

        </Row>
      
        </div>
            
       
      { musicplayer ? <> 
       
       
            {popularSongs.length > 0 && (
             
 

		    <div className="audio-player-container">
		                   <div className="playdetails">
                  {currentSong && (
                    <>
                   

                      <p>{currentSong.description} ,</p>
	   <p>{currentSong.language}</p>
                    </>
                  )}
                </div>
                <AudioPlayer
                  src={currentSong.cp_url}
                     showSkipControls={true}
                onClickNext={handleNextSong}
                onClickPrevious={handlePreviousSong}
		    autoPlay={true}
                />
              </div>
            )}
       {popularSongs.length === 0 && (
         <div style={{ textAlign: 'center', background: 'green', padding: '20px', margin: '40px' }}>
           No Songs to Play
         </div>
	      
       )}
      
      </>:''}
      
      
      
                  </div>
           
  )
}
export default Music;

